<template>
  <div class="accommodation-search">
    <div class="accommodation-search--content">
      <HoliduSearch />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    HoliduSearch: () => import('@/components/search/HoliduSearch')
  }
};
</script>

<style lang="scss" scoped>
.accommodation-search--button {
  padding: 0.25rem 0.75rem;
  background-color: white;
  color: $gray-900;
  border-radius: 6px;
  margin-right: 1rem;
}

.accommodation-search--button:hover,
.accommodation-search--button:focus {
  background-color: $teal-200;
}

.accommodation-search--button:active {
  background-color: $teal-200;
}

.accommodation-search--button[aria-selected] {
  background-color: $teal-200;
}
</style>
